(function($) {
  $(window).load(function() {
    svg.init();
    nav.init();
    scrollTo.init();
    contact.init();
    location.init();
    articleReadMore.init();
    var myAccordion = new $.Zebra_Accordion($('.Zebra_Accordion'), {
      'show': false,
      'collapsible': true
    });

    $('#search').on('focus', function(e) {
      $(e.target).parents('.search-form').addClass('focus');
    });

    $('#search').on('blur', function(e) {
      if (e.target.value.trim() === '') {
        $(e.target).parents('.search-form').removeClass('focus');
      }
    });

    $('.reveal-activate-link').click(function(e) {
      e.preventDefault();
      $('.reveal').css('height', 'auto');
      var target = $('.reveal').height();
      $('.reveal').css('height', '0');

      $('.reveal').animate({
        height: target +'px'
      }, {
        complete: function() {
          $('.reveal').css('height', 'auto');
        }
      });

      $('.reveal-activate, .reveal-activate-link').fadeOut();
    });

  });

   /* NON-SVG BROWSER FUNCTIONS */
  var svg = (function(obj) {
    obj.init = function() {
      if(!Modernizr.svg) {
        $('img.png-svg, img.svg, img.no-svg').each(function() {
          var $this = $(this);
          var png = $this.attr('data-png');
          if(png !== undefined) {
            $this.attr('src', png);
          }
        });
      }
    };
    return obj;
  })(svg || {});

  var scrollTo = (function(_this) {
    var $links, $header;
    _this.init = function() {
      $links = $('.nav-item a, a.scrollto');
      $header = $('#header');
      // Bind click/scroll handlers to each
      $links.each(function() {
        $(this).click(function(e) {
          $links.removeClass('active');
          $(this).addClass('active');
          var $target = $($(this).attr('href').replace('/', ''));
          if($target.length) {
            e.preventDefault();
            nav.hide();
            $('html, body').stop().animate({
              scrollTop: $target.offset().top - $header.height() - 4
            },{
              complete: function() {
                nav.scroll();
              }
            });
            setTimeout(nav.scroll, 50);
          }
        });
      });
    };
    return _this;
  })(scrollTo || {});

  var nav = (function(_this) {
    var $header,
        $nav,
        $toggle;
    _this.init = function() {
      $header = $('#header');
      $nav = $('#nav');
      $toggle = $('#nav-toggle');

      $toggle.click(function(e) {
        e.preventDefault();
        if($toggle.hasClass('active')) {
          _this.hide();
        } else {
          _this.show();
        }
      });
      // Bind scroll handler
      if (window.addEventListener) // older FF
        window.addEventListener('DOMMouseScroll', _this.scroll, false);
      window.onwheel = _this.scroll; // modern standard
      window.onmousewheel = document.onmousewheel = _this.scroll; // older browsers, IE
      window.ontouchstart  = _this.scroll; // mobile - disabled

      _this.scroll();


      $('body').bind('click', function(e) {
        if($(e.target).closest('#header').length == 0) {
          // click happened outside of menu, hide any visible menu items
          if($toggle.hasClass('active')) {
            _this.hide();
          }
        }
      });
    };

    _this.scroll = function(e) {
      if($('#header').hasClass('always')) {
        return;
      }
      
      if($(window).scrollTop() > 80) {
        $header.addClass('small');
      } else {
        $header.removeClass('small');
      }
    };

    _this.show = function() {
      $toggle.addClass('active');
      $nav.css('height', 'auto');
      var targetH = $nav.height();
      $nav.css('height', '0');

      $nav.stop().animate({
        height: targetH + 'px'
      }, {
        complete: function() {
          $nav.css('height', 'auto');
        }
      });
    };

    _this.hide = function() {
      $toggle.removeClass('active');
      $nav.stop().animate({
        height: '0'
      });
    };
    return _this;
  })(nav || {});

  var contact = (function(obj) {
    var $form,
        $name,
        $email,
        $phone,
        $postcode,
        $message,
        $_token,
        $submit;
    obj.init = function() {

      $form = $('#contact-form');
      $name = $('#contact-form .name');
      $email = $('#contact-form .email');
      $message = $('#contact-form .message');
      $token = $('input[name=_token]');
      $submit = $('#contact-form .form-submit');
      $form.submit(function(e) {
        obj.submit();
        return false;
      });
      // Bind input handlers to each for validation
      $('.form-text, .form-textarea').on('focus', obj.focus);
      $('.form-text, .form-textarea').on('blur', obj.blur);
      $('.form-text, .form-textarea').on('keyup', obj.validate);
    };
    obj.focus = function(e) {
      $(e.target.parentNode).addClass('focus');
    };

     obj.blur = function(e) {
      if (e.target.value.trim() === '') {
        $(e.target.parentNode).removeClass('focus');
      }
    };

    obj.validate = function() {
      var valid = true;
      if ($name.val().trim() === '') { // Name is required
        valid = false;
        $name.parent().addClass('invalid');
      } else {
        $name.parent().removeClass('invalid');
      }


      if($email.val().trim() === '' || !obj.validateEmail($email.val().trim()) ) {
        valid = false;
        $email.parent().addClass('invalid');
      } else {
        $email.parent().removeClass('invalid');
      }


      if ($message.val().trim() === '') { // Name is required
        valid = false;
        $message.parent().addClass('invalid');
      } else {
        $message.parent().removeClass('invalid');
      }

      if (valid) {
        $submit.removeClass('disabled');
        $submit.removeProp('disabled');
      } else {
        $submit.addClass('disabled');
        $submit.prop('disabled', 'true');
      }
    };


    obj.validateEmail = function(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    obj.submit = function() {
      $submit.find('.text').hide();
      $submit.find('.loading').addClass('show');

      // disable the button while submitting
      $submit.addClass('disabled, submitting');
      $submit.prop('disabled', 'true');
      var name = $('#name').val();
      var data = {
        'contact': true,
        'name': $name.val(),
        'email': $email.val(),
        'message': $message.val(),
        '_token': $token.val(),
        'method': 'post'
      };

      $.post('/', data,
        // Success handler
        function(result_json) {
          if(result_json.success) {
            $submit.removeClass('disabled, submitting');
            $submit.removeProp('disabled');
            $submit.find('.text').html('SENT!');
            $submit.find('.text').show();
            $submit.find('.loading').removeClass('show');
            setTimeout(function() {
              $submit.find('.text').html('SUBMIT');
              $name.val('');
              $email.val('');
              $message.val('');
              $submit.addClass('disabled');
              $submit.prop('disabled', 'true');
              $('.form-text, .form-textarea').trigger('blur');
            }, 3000);
          }
        });
    };
    return obj;
  })(contact || {});


  var location = (function(obj) {
    var $map;
    var locations = [
      {
        'title': 'Kineric Sports Physiotherapy',
        'icon': '/images/marker.png',
        'lat': -32.92659786952026,
        'lng': 151.77791043093058
      }
    ];
    obj.init = function() {
      $map = $('#gmap');

      if(!$map.length) {
        return;
      }


      // initialize GMAPs
      map = new google.maps.Map($map[0], {
        center: {lat: locations[0].lat, lng: locations[0].lng},
        zoom: 17,
        scrollwheel: false,
        navigationControl: false,
        mapTypeControl: false,
        scaleControl: false,
        styles: [{"featureType":"landscape","stylers":[{"saturation":-100},{"lightness":65},{"visibility":"on"}]},{"featureType":"poi","stylers":[{"saturation":-100},{"lightness":51},{"visibility":"simplified"}]},{"featureType":"road.highway","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"road.arterial","stylers":[{"saturation":-100},{"lightness":30},{"visibility":"on"}]},{"featureType":"road.local","stylers":[{"saturation":-100},{"lightness":40},{"visibility":"on"}]},{"featureType":"transit","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"administrative.province","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"labels","stylers":[{"visibility":"on"},{"lightness":-25},{"saturation":-100}]},{"featureType":"water","elementType":"geometry","stylers":[{"hue":"#ffff00"},{"lightness":-25},{"saturation":-97}]}]
      });

      for(var i in locations) {
        var marker = new google.maps.Marker({
          position: {lat: locations[i].lat, lng: locations[i].lng},
          map: map,
          title: locations[i].title,
          icon: locations[i].icon
        });
      }
    };

    return obj;
  })(location || {});

    // Module: ArticleReadMore - Controls the reveal effect of
  //  truncated news items on the News Listing page
  var articleReadMore = (function(obj) {
    var $reveals;
    obj.init = function() {

      $reveals = $('.article .expand');

      $(window).resize(obj.resize);
      obj.resize();

      $reveals.each(function() {
        var $this = $(this);
        $this.click(function(e) {
          var $link = $(this);
          // find the short body, grab its height, then hide it
          e.preventDefault();
          
          var $fullBody = $link.siblings('.body');
          var oldHeight = $fullBody.innerHeight();
          $fullBody.css('height', 'auto');
          var targetHeight = $fullBody.innerHeight();
          $fullBody.css('height', oldHeight);
          $fullBody.animate({
            height: targetHeight+'px'
          }, {
            complete: function() {
              $fullBody.find('.shadow').remove();
              $fullBody.css('height', 'auto');
            }
          });
          $link.animate({
            'height': '0px'
          }, {
            complete: function() {
              $link.remove();
            }
          });
        });
      });

      var slugs = window.location.pathname.split('/');
      if(slugs.length > 2 && slugs[1] === 'blog') {
          $('#header').addClass('small');
          var $target = $('#' + slugs[2]);
          $('html, body').animate({
            scrollTop: $target.offset().top
          }, 500);

          $target.find('.expand').click();
      }
    };

    obj.resize = function() {
      // hide the reveal more layer when there is a small amount of content
      $reveals.each(function() {
        var $fullBody = $(this).siblings('.body');
        var $shadow = $fullBody.find('.shadow');
        if($fullBody.children('.inner').height() < 200) {
          $(this).hide();
          $shadow.hide();
        } else {
          $(this).show();          
          $shadow.show();          
        }
      });
    };
    return obj;
  })(articleReadMore || {});


/*
  var a = (function(_this) {
    var $b;
    _this.init = function() {
      $b = $();
    };
    return _this;
  })(a || {});
*/
})(jQuery);